<!--
 * =====================================================================
 * 작 업 명  :
 * 파 일 명  : DriveList.vue
 * 변경이력
 * 2022-12-12 / 이성준 / 최초작성
 * =====================================================================
 -->
<template>
    <main id="main">
        <div class="container-fluid h-100"
             @click="hideContextmenu"
             @contextmenu.stop.prevent="onContextmenu(null, $event)">
            <template v-if="isLogin">
                <div v-if="filteredItems.length" class="row row-cols-auto p-1" role="list" style="gap: 2rem 3rem">
                    <template v-if="viewMode.description === 'CARD'">
                        <drive-card-list
                            :filteredItems="filteredItems"
                            @onFormatDate="formatDate"
                            @onEnter="onEnter"
                            @onContextmenu="onContextmenu"/>
                    </template>
                    <template v-else-if="viewMode.description === 'LIST'">
                        <drive-grid-list
                            :filteredItems="filteredItems"
                            @onFormatDate="formatDate"
                            @onEnter="onEnter"
                            @onContextmenu="onContextmenu"/>
                    </template>
                </div>
                <span v-else-if="items.length && !filteredItems.length" class="text">{{ $t('검색 결과가 없습니다.') }}</span>
                <span v-else class="text">{{ $t('파일이 없습니다.') }}</span>
            </template>
            <span v-else-if="!isLogin" class="text">{{ $t('로그인 후 다시 시도해 주세요.') }}</span>
        </div>
    </main>
    <context-menu ref="contextMenu" :selectedItem="selectedItem" :currentPath="currentPath" @onEnter="onEnter"/>
    <preview ref="preview" :selectedItem="selectedItem"/>
</template>

<script>
import {defineAsyncComponent} from 'vue'
import {mapGetters} from "vuex";
import {router} from "@/routes";
import DriveCardList from "@/views/drive/DriveCardList.vue";
import global from "@/js/global";

const DriveGridList = defineAsyncComponent(() => import("@/views/drive/DriveGridList.vue"));
const ContextMenu = defineAsyncComponent(() => import ("@/views/drive/ContextMenu.vue"));
const Preview = defineAsyncComponent(() => import ("@/views/drive/Preview.vue"));

export default {
    name: "DriveList",
    components: {
        DriveCardList,
        DriveGridList,
        ContextMenu,
        Preview
    },
    props: {
        textFilter: String,
        currentPath: Object,
    },
    data() {
        return {
            selectedItem: {}, // 선택한 항목(우클릭, 프리뷰)
            isLogin: global.isLogin(),
            clipboard: null,
            clipboardMode: null,
        }
    },
    computed: {
        ...mapGetters("drive", [
            "driverKind",
            "items",
            "viewMode",
        ]),
        filteredItems() {
            return this.$store.getters['drive/filteredItems'](this.textFilter);
        },
    },
    watch: {
        driverKind() {
            this.hideContextmenu();
            this.reloadItems(this.$route.query.parentGroupNo);
        },
        '$route.query': {
            handler: function (to) {
                this.reloadItems(to.parentGroupNo);
            },
            deep: true,
            immediate: true,
        }
    },
    updated() {
        if (this.viewMode.description === 'CARD') {
            this.$store.dispatch('drive/createDTMSCanvasOfCardTypeItems');
        }
    },
    methods: {
        async reloadItems(parentGroupNo) {
            return await this.$store.dispatch('drive/reloadItems', {parentGroupNo: parentGroupNo});
        },
        onEnter(data) {
            if (data.FILE_TYPE === 'FOLDER') {
                const params = {
                    type: this.$store.getters['drive/driverKind'],
                    parentGroupNo: data.FILE_KEY
                }
                router.push({name: "cloud", query: params});
            } else if (data.FILE_TYPE === 'FILE') {
                this.selectedItem = data;
                this.$refs.preview.$data.modal.show();
            }
        },
        onContextmenu(data, event) {
            this.selectedItem = data;
            this.$refs.contextMenu.onRowClicked(event);
        },
        hideContextmenu() {
            this.$refs.contextMenu.hidePopup();
        },
        formatDate(date, callback) {
            const options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric',
                hour12: false,
            };

            return callback(new Intl.DateTimeFormat(this.$i18n.locale, options).format(new Date(date)));
        },
    }
}
</script>

<style scoped>
#main {
    /* when there is a footer */
    /*height: 59vh;*/
    /* when there is not a footer */
    height: 50vh;
    overflow: auto;
    margin-top: 50px;
}
.container-fluid {
    margin: 0 auto;
    /* width: 1650px; */
}

.text {
    font-size: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
