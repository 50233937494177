<template>
    <div class="col">
        <div class="card h-100 bg-transparent p-1 border-0 "
             tabindex="0"
             role="listitem"
             @dblclick="onEnter"
             @keydown.enter="onEnter">
            <slot name="top"/>
            <div class="card-body p-0 pt-3">
                <p class="card-title text-center text-truncate text-wrap mb-0">
                    <slot name="title"/>
                </p>
                <p class="card-text text-center">
                    <slot name="text"/>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        filtered: Boolean
    },
    emits: ['onEnter'],
    methods: {
        onEnter() {
            this.$emit('onEnter');
        }
    }
}
</script>

<style scoped>
p {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.card {
    width: 14rem;
    cursor: pointer;
}

.card:focus {
  outline:  3px solid #EA5414;
}

.card-text {
    color: #AAABAB;
}
.card-title {
    font-size: 18px;
    /* max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important; */
    
}
.card-text {
    font-size: 16px;
}
</style>
